import React, { useState } from "react";
import LoginComponent from "./login";
import { connect } from "react-redux";
import { login } from "../../redux/slice/loginSlice";
import Notify from "../common/Notify/notify";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { trimValue, validEmail } from "../../utils/commonUtils";
import { useGoogleLogin } from "@react-oauth/google";
import { googleOauth2 } from "../../redux/slice/googleOauth2Slice";
import { googleLogin } from "../../redux/slice/googleLoginSlice";

const Login = (props) => {
  const { callLoginData, loginData, callGoogleOauth2, callGoogleLogin } = props;
  const cookies = new Cookies();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = cookies.get("lang");
  let emailString = t("ENTER_VALID_EMAIL");
  let modifiedEmailString = emailString.replace(
    /^./,
    emailString[0].toUpperCase()
  );
  const [error, setErrors] = useState();
  const [emailError, setEmailErrors] = useState();
  const [googleErrors, setGoogleErrors] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const validateEmail = (value) => {
    if (!validEmail.test(value)) {
      setEmailErrors(modifiedEmailString);
      return false;
    } else {
      setEmailErrors("");
      return true;
    }
  };

  const handleLoginSuccess = async (oauthData, type) => {
    setIsLoading(true);
    let externalData = {
      email: oauthData.email,
      loginId: oauthData.id,
      type: type,
    };
    const result = await callGoogleLogin(externalData);
    setIsLoading(false);
    if (result.type === "googleLogin/rejected") {
      setGoogleErrors(result.error.message);
    }
    if (result.type === "googleLogin/fulfilled") {
      Notify("success", t("NOTIFY_LOGIN"), "");
      cookies.set("token", result.payload.data.token);
      cookies.set("first_name", result.payload.data.first_name);
      cookies.set("last_name", result.payload.data.last_name);
      cookies.set("role", result.payload.data.role);
      navigate(`/${lang}/dash`);
    }
  };

  const handleLogin = async (values) => {
    const validate = validateEmail(values.email);
    if (!validate) {
      return;
    }
    const trimmedValue =
      typeof values.email === "string"
        ? values.email.replace(/^\s+/g, "")
        : values.email;
    const loginData = {
      email: trimValue(trimmedValue)?.toLowerCase(),
      password: values.password,
    };
    const result = await callLoginData(loginData);
    if (result.type === "login/rejected") {
      setErrors(result.error.message);
    }
    if (result.type === "login/fulfilled") {
      Notify("success", t("NOTIFY_LOGIN"), "");
      cookies.set("token", result.payload.data.token, { path: "/" });
      cookies.set("first_name", result.payload.data.first_name);
      cookies.set("last_name", result.payload.data.last_name);
      cookies.set("role", result.payload.data.role);
      navigate(`/${result.payload.data.language}/dash`);
    }
  };
  const handleValuesChange = () => {
    setEmailErrors(null);
    setErrors(null);
    setGoogleErrors(null);
  };
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setIsLoading(true);
      let res = await callGoogleOauth2(codeResponse.access_token);
      if (res.type === "googleOauth2/fulfilled") {
        await handleLoginSuccess(res.payload, "google");
      }
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  // sign with facebook
  const responseFacebook = async (response) => {
    if (!response || response.status === "unknown" || response.error) return;

    if (!response.email) {
      setGoogleErrors(t("FACEBOOK_EMAIL_PERMISSION"));
    } else {
      await handleLoginSuccess(response, "facebook");
    }
  };

  return (
    <LoginComponent
      handleLogin={handleLogin}
      loginData={loginData}
      loginError={error}
      emailError={emailError}
      handleValuesChange={handleValuesChange}
      login={login}
      googleErrors={googleErrors}
      isLoading={isLoading}
      responseFacebook={responseFacebook}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callLoginData: (data) => dispatch(login(data)),
    callGoogleOauth2: (data) => dispatch(googleOauth2(data)),
    callGoogleLogin: (data) => dispatch(googleLogin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
