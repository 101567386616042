import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { useLocation } from "react-router-dom";

const AppLayout = ({ children }) => {
  const location = useLocation();
  const navArray = [
    "dash",
    "settings",
    "l",
    "admin",
    "track",
    "currency",
    "trace",
    "tracking",
    "email-test",
    "sms-config",
  ];
  return (
    <React.Fragment>
      {!navArray.includes(location.pathname.split("/")[2]) && (
        <>
          <Header />
        </>
      )}
      {children}
      {!navArray.includes(location.pathname.split("/")[2]) && (
        <>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
};

export default AppLayout;
